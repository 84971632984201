import {mapActions, mapGetters, mapState} from 'vuex'
import Vue from 'vue'

export const api_mixin = {
    data() {
        return {}
    },
    computed: {
        ...mapGetters({
            decoded_token: 'decoded_token',
        }),
        ...mapState({
            token: (state) => state.token,
            media_files: (state) => state.media_files,
            refresh_token_in_progress: (state) => state.refresh_token_in_progress,
        }),
    },
    methods: {
        async api_get(payload) {
            const response = await Vue.axios.get(process.env.VUE_APP_API_ROOT + payload.url, {
                headers: {
                    'X-API-Key': await this.get_valid_token(payload),
                    'Cache-Control': 'no-cache',
                },
                params: payload.params,
            })
            if (payload.commit) {
                payload['data'] = response.data?._items || response.data
                if (payload.key) {
                    this.update_key_in_state_property(payload)
                } else if (payload.push) {
                    this.push_state_property(payload)
                } else if (payload.concatenate) {
                    this.concatenate_state_property(payload)
                } else {
                    this.set_state_property(payload)
                }
            }
            return response
        },
        async api_post(payload) {
            return await Vue.axios.post(process.env.VUE_APP_API_ROOT + payload.url, payload.data, {
                headers: {
                    'X-API-Key': await this.get_valid_token(payload),
                },
            })
        },
        async api_delete(payload) {
            return await Vue.axios.delete(process.env.VUE_APP_API_ROOT + payload.url, {
                headers: {
                    'X-API-Key': await this.get_valid_token(payload),
                    'If-Match': payload['if_match'],
                },
            })
        },
        async api_patch(payload) {
            return await Vue.axios.patch(process.env.VUE_APP_API_ROOT + payload.url, payload.data, {
                headers: {
                    'X-API-Key': await this.get_valid_token(payload),
                    'If-Match': payload['if_match'],
                },
            })
        },
        async api_put(payload) {
            return await Vue.axios.put(process.env.VUE_APP_API_ROOT + payload.url, payload.data, {
                headers: {
                    'X-API-Key': await this.get_valid_token(payload),
                    'If-Match': payload['if_match'],
                },
            })
        },
        async api_get_all_items(resource, sort = '', where = {}, projection = {}) {
            let page = 1
            let max_results = 50
            let items = []

            let total = undefined
            let continue_to_fetch = true

            while (continue_to_fetch) {
                let request = await this.api_get({
                    url: resource,
                    params: {where, sort, projection, page, max_results},
                })
                if (total === undefined) {
                    total = request.data['_meta']['total']
                }

                items = items.concat(request.data['_items'])
                if (items.length >= total) {
                    continue_to_fetch = false
                } else {
                    page += 1
                }
            }

            return items
        },

        async get_valid_token(payload) {
            if (!this.token_expired() || payload.url.includes('authenticates')) {
                return this.token
            }

            this.set_state_property({
                property: 'token_has_expired',
                data: true,
            })

            let time_waited = 0
            const interval_to_sleep_ms = 500
            while (this.token_expired()) {
                await this.sleep(interval_to_sleep_ms)
                time_waited += interval_to_sleep_ms
                if (time_waited > 1000 * 5) {
                    break
                }
            }
            return this.token
        },
        token_expired() {
            return Date.now() >= (this.decoded_token?.exp || 0) * 1000
        },
        async sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms))
        },

        async get_media_file(resource, resource_id, media_id, expiration_in_hours = 48) {
            if (!media_id) return null

            if (this.media_files[media_id] && this.media_files[media_id].expiration > Date.now()) {
                return this.media_files[media_id]
            }

            const response = await this.api_get({
                url: `media--url/${resource}/${resource_id}/${media_id}/${expiration_in_hours * 60}`,
            })

            const media_file = response.data
            media_file.expiration = Date.now() + 1000 * 60 * 60 * expiration_in_hours

            this.update_key_in_state_property({
                property: 'media_files',
                key: media_id,
                data: media_file,
            })

            return media_file
        },
        ...mapActions([
            'set_state_property',
            'push_state_property',
            'update_key_in_state_property',
            'concatenate_state_property',
        ]),
    },
}
