import { mapActions, mapGetters, mapState } from 'vuex'
import { api_mixin } from '@/mixins/services/api_mixin'
import { course_mixin } from '@/mixins/components/course_mixin'
import { module_mixin } from '@/mixins/components/module_mixin'
import { program_mixin } from '@/mixins/components/program_mixin'

import { debounce } from 'lodash'
import { statistics_mixin } from '@/mixins/components/statistics_mixin'

export const progress_mixin = {
    mixins: [api_mixin, course_mixin, module_mixin, program_mixin, statistics_mixin],
    data() {
        return {
            grade_levels: {
                gold: 100,
                silver: 70,
                bronze: 0.0001,
            },
            user_owned_progress_keys: [
                'course_levels',
                'program_completions',
                'nickname',
                'selected_locale',
                'best_score_interview_game',
                'number_interview_games',
                'interview_level',
                'cv_level',
                'job_application_level',
                'latest_activity',
            ],
        }
    },
    computed: {
        ...mapGetters(['courses_by_module']),
        ...mapState([
            'programs',
            'user_extended_data',
            'user_progress',
            'courses',
            'total_courses_per_module',
            'total_modules_per_program',
            'total_courses',
        ]),
    },
    methods: {
        save_latest_activity_on_exit() {
            this.update_key_in_state_property({
                property: 'user_progress',
                data: new Date().toUTCString(),
                key: 'latest_activity',
            })
            this._save_user_progress()
        },
        throttle_save_user_progress: debounce(async function () {
            /*Only called from App.vue*/
            await this._save_user_progress()
        }, 5000),
        async _save_user_progress(custom_etag = null) {
            try {
                const data = {}

                this.user_owned_progress_keys.forEach((key) => {
                    if (this.user_progress[key]) {
                        if (key === 'program_completions') {
                            //Handle stuff that has readonly keys
                            const readonly_keys = ['program_info']
                            data[key] = this.user_progress[key].map((x) => {
                                const object = {}
                                for (let [k, v] of Object.entries(x)) {
                                    if (readonly_keys.includes(k)) continue
                                    object[k] = v
                                }
                                return object
                            })
                        } else {
                            data[key] = this.user_progress[key]
                        }
                    }
                })
                data.latest_activity = new Date().toUTCString()

                const response = await this.api_patch({
                    url: `x-u2work--progress-users/${this.user_progress._id}`,
                    data: data,
                    if_match: custom_etag || this.user_progress._etag,
                })
                this.update_key_in_state_property({
                    property: 'user_progress',
                    data: response.data._etag,
                    key: '_etag',
                })
            } catch (e) {
                if (custom_etag) {
                    throw e
                }

                const response = await this.api_get({
                    url: `x-u2work--progress-users/${this.user_progress._id}`,
                    params: {
                        projection: {
                            _id: 1,
                        },
                    },
                })
                await this._save_user_progress(response.data._etag)
            }
        },
        async load_user_progress() {
            const response = await this.api_get({
                url: 'x-u2work--progress-users',
                params: {
                    where: {
                        user: this.decoded_token.u_id,
                    },
                },
            })
            let user_progress = response.data._items[0]
            if (!user_progress) {
                user_progress = await this.create_user_progress()
            }
            this.set_state_property({
                property: 'user_progress',
                data: user_progress,
            })
        },
        async create_user_progress() {
            console.log('create_user_progress ')
            const course_levels = []
            await this.ensure_all_course_levels_exists(course_levels)
            await this.ensure_course_level_is_sorted(course_levels)

            const nicknames = await import('@/data/nicknames.json')
            const nickname = nicknames[Math.round(Math.random() * nicknames.length) - 1]

            const new_user_progress = {
                user: this.user_extended_data.user,
                nickname: nickname,
                course_levels: course_levels,
                cv_level: 'none',
                job_application_level: 'none',
                number_interview_games: 0,
                latest_activity: new Date().toUTCString(),
            }
            // if (this.user_extended_data.organization) {
            //     new_user_progress['organization'] = this.user_extended_data.organization
            // }
            // if (this.user_extended_data.organization_unit) {
            //     new_user_progress['unit'] = this.user_extended_data.organization_unit
            // }
            // if (this.user_extended_data.manager) {
            //     new_user_progress['manager'] = this.user_extended_data.manager
            // }
            new_user_progress.program_completions = await this.calculate_program_completions(
                new_user_progress.course_levels
            )
            const response = await this.api_post({
                url: 'x-u2work--progress-users',
                data: new_user_progress,
            })

            new_user_progress['_id'] = response.data._id
            new_user_progress['_etag'] = response.data._etag
            new_user_progress['_updated'] = response.data._updated
            new_user_progress['_created'] = response.data._created

            return new_user_progress
        },
        async get_course_level(course) {
            let existing_course_level = this.user_progress?.course_levels?.find((x) => x.course === course._id)

            if (existing_course_level) {
                return existing_course_level
            }
            return this.new_course_level(course)

            // return await this.add_course_level(course)
        },
        async new_course_level(course) {
            const new_course_level = {
                course: course._id,
            }

            this.ensure_foreign_links(new_course_level, course)
            this.ensure_course_level_content_match_course_content(new_course_level, course)

            return new_course_level
        },
        async update_course_levels(course_level, course) {
            course_level.last_visited = new Date().toUTCString()
            this.ensure_foreign_links(course_level, course)
            this.ensure_course_level_content_match_course_content(course_level, course)
            await this.ensure_foreign_link_info(course_level, course)

            const current_course_level = course_level.level || 'none'
            const new_course_level = this.evaluate_course_grade(course_level, course)

            if (current_course_level !== new_course_level) {
                this.add_new_medal_statistic('course', new_course_level, true)
            }

            course_level.level = new_course_level

            const user_progress_copy = structuredClone(this.user_progress)
            if (!user_progress_copy.course_levels) {
                user_progress_copy.course_levels = []
            }
            let course_levels = user_progress_copy.course_levels

            const id_keys = ['program', 'module', 'course']
            let existing_course_level = course_levels.find((x) => {
                for (let key of id_keys) {
                    if (course_level[key] !== x[key]) {
                        return false
                    }
                }
                return true
            })

            let sort_course_levels = false
            if (!existing_course_level) {
                sort_course_levels = true
                course_levels.push(course_level)
            } else {
                existing_course_level = course_level
            }

            if (sort_course_levels) {
                await this.ensure_course_level_is_sorted(course_levels)
            }

            user_progress_copy.program_completions = await this.calculate_program_completions(course_levels)
            user_progress_copy.latest_activity = new Date().toUTCString()

            this.set_state_property({
                property: 'user_progress',
                data: user_progress_copy,
            })
        },
        calculate_interview_game_progress(trophy, score, total_games) {
            const user_progress_copy = structuredClone(this.user_progress)
            const trophy_levels = ['none', 'bronze', 'silver', 'gold']

            if (trophy_levels.indexOf(trophy) > trophy_levels.indexOf(user_progress_copy['interview_level'])) {
                user_progress_copy['interview_level'] = trophy
            }

            if (user_progress_copy['best_score_interview_game'] === undefined) {
                user_progress_copy['best_score_interview_game'] = score
            } else if (score > user_progress_copy['best_score_interview_game']) {
                user_progress_copy['best_score_interview_game'] = score
            }

            if (total_games) {
                user_progress_copy.number_interview_games = total_games + 1
            } else {
                user_progress_copy.number_interview_games = 1
            }

            user_progress_copy.latest_activity = new Date().toUTCString()

            this.set_state_property({
                property: 'user_progress',
                data: user_progress_copy,
            })
        },
        evaluate_course_grade(course_level, course) {
            const percent_finished = this.percent_finished_on_course(course_level, course)
            return this.grade_color(percent_finished)
        },
        grade_color(percent_finished) {
            for (let [grade, grade_score] of Object.entries(this.grade_levels)) {
                if (percent_finished >= grade_score) {
                    return grade
                }
            }

            return null
        },
        async ensure_all_course_levels_exists(course_levels) {
            console.log(this.total_courses)
            if (course_levels.length === this.total_courses) return

            await this.load_courses()
            await this.load_modules()
            await this.load_programs()

            for (let course of this.courses) {
                if (course_levels.find((x) => x.course === course)) continue
                const new_course_level = {
                    course: course._id,
                    course_info: course.info,
                    content: [],
                    last_visited: new Date().toUTCString(),
                    level: 'none',
                }
                this.ensure_course_level_content_match_course_content(new_course_level, course)

                if (course.program) {
                    const program = await this.get_program(course.program)

                    new_course_level.program = course.program
                    new_course_level.program_info = program.info
                }

                if (course.module) {
                    const module = await this.get_module(course.module)

                    new_course_level.module = course.module
                    new_course_level.module_info = module.info
                }

                course_levels.push(new_course_level)
            }
        },
        ensure_foreign_links(course_level, course) {
            const foreign_links = ['program', 'module']
            for (let key of foreign_links) {
                if (course_level[key] || !course[key]) continue

                course_level[key] = course[key]
            }
        },
        ensure_course_level_content_match_course_content(course_level, course) {
            if (!course_level.content) course_level.content = []

            const diff_in_content = course.content.length - course_level.content.length
            if (diff_in_content < 0) {
                // Remove content
                course_level.content.slice(diff_in_content)
            } else if (diff_in_content > 0) {
                // Add content
                for (let i = 0; i < diff_in_content; i++) {
                    course_level.content.push({ seconds_on_content: 0 })
                }
            }
        },
        async ensure_foreign_link_info(course_level, course) {
            if (!course_level.course_info) {
                course_level.course_info = course.info
            }
            if (course_level.program && !course_level.program_info) {
                course_level.program_info = (await this.get_program(course_level.program))['info']
            }
            if (course_level.module && !course_level.module_info) {
                course_level.module_info = (await this.get_module(course_level.module))['info']
            }
        },
        async ensure_course_level_is_sorted(course_levels) {
            //Make sure we have all courses before sorting
            let promises = []
            for (let course_level of course_levels) {
                promises.push(this.get_course(course_level.course))
            }
            for (let p of promises) {
                await p
            }

            course_levels.sort((a, b) => {
                if (a.program < b.program) {
                    return -1
                } else if (a.program > b.program) {
                    return 1
                }
                return 0
            })

            course_levels.sort((a, b) => {
                if (a.module < b.module) {
                    return -1
                } else if (a.module > b.module) {
                    return 1
                }
                return 0
            })
            course_levels.sort(async (a, b) => {
                if (a.program !== b.program) return 0
                if (a.module !== b.module) return 0

                const a_course = await this.get_course(a.course)
                const b_course = await this.get_course(b.course)
                return a_course.sort_order - b_course.sort_order
            })
        },
        async calculate_program_completions(course_levels) {
            const modules_per_program = {}
            for (let course_level of course_levels) {
                if (!course_level.program || !course_level.module) continue

                if (!modules_per_program[course_level.program]) {
                    modules_per_program[course_level.program] = []
                }
                if (modules_per_program[course_level.program].includes(course_level.module)) {
                    continue
                }
                modules_per_program[course_level.program].push(course_level.module)
            }

            const program_completions = []

            for (let [program_id, module_ids] of Object.entries(modules_per_program)) {
                let total_module_progress_for_program = 0

                for (let module_id of module_ids) {
                    total_module_progress_for_program += await this.get_module_progress(module_id)
                }
                let total_modules = this.total_modules_per_program[program_id]
                if (total_modules === undefined) {
                    total_modules = await this.get_total_modules_for_program(program_id)
                }
                program_completions.push({
                    program: program_id,
                    percent_completed: total_module_progress_for_program / total_modules,
                })
            }

            return program_completions
        },
        async get_program_progress(program_id) {
            const program_completion = this.user_progress?.program_completions?.find((x) => x.program === program_id)

            if (!program_completion) return 0

            return program_completion.percent_completed
        },
        async get_module_progress(module_id) {
            if (!this.user_progress?.course_levels) return 0
            const filtered_course_levels = this.user_progress.course_levels.filter((x) => x.module === module_id)

            let total_progress = 0
            for (let course_level of filtered_course_levels) {
                total_progress += await this.get_course_progress(course_level.course)
            }

            let total_courses = this.total_courses_per_module[module_id]
            if (total_courses === undefined) {
                total_courses = await this.get_total_courses_for_module(module_id)
            }
            return total_progress / total_courses
        },
        async get_course_progress(course_id, course = null) {
            if (!course) {
                course = await this.get_course(course_id)
            }

            const course_level = this.user_progress.course_levels?.find((x) => x.course === course_id)
            if (!course_level) return 0

            return this.percent_finished_on_course(course_level, course)
        },
        percent_finished_on_course(course_level, course) {
            const percent_finished_per_content = course_level.content.map((element, index) => {
                const seconds_on_content = element.seconds_on_content
                const seconds_to_completed = course?.content?.[index]?.seconds_to_completed || 1

                const content_percent_finished = (seconds_on_content / seconds_to_completed) * 100

                if (content_percent_finished > 100) return 100

                return content_percent_finished
            })

            return (
                percent_finished_per_content.reduce((previousValue, currentValue) => previousValue + currentValue, 0) /
                percent_finished_per_content.length
            )
        },

        ...mapActions(['set_state_property', 'update_key_in_state_property', 'patch_state_property']),
    },
}
