<template>
    <v-dialog
        v-model="dialog"
        :max-width="size.max_width || 'auto'"
        :height="size.height || 'auto'"
        :fullscreen="$vuetify.breakpoint.xsOnly"
    >
        <v-card
            :width="size.width || 'auto'"
            :height="size.height || 'auto'"
            :max-height="size.max_height || 'auto'"
            :max-width="size.max_width || 'auto'"
            elevation="12"
        >
            <v-btn
                @click="dialog = false"
                style="position: absolute; top: 0; right: 0; z-index: 10"
                class="ma-2"
                icon
            >
                <v-icon v-text="'mdi-close'" />
            </v-btn>
            <component
                v-if="dialog_component"
                :is="dialog_component"
                :course="dialog_data.course"
                @close="dialog = false"
            />
        </v-card>
    </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
    name: 'TheAppDialog',
    props: {},
    mixins: [],
    components: {
        CourseNps: () => import(/*webpackChunkName "CourseNps"*/ '@/components/course_nps'),
    },
    data() {
        return {
            dialog: false,
        }
    },
    computed: {
        dialog_component() {
            return this.app_dialog?.component
        },
        dialog_data() {
            return this.app_dialog?.data || {}
        },
        size() {
            return this.app_dialog?.size || {}
        },
        ...mapGetters([]),
        ...mapState(['app_dialog']),
    },
    watch: {
        dialog: {
            handler(val) {
                if (val === false && this.dialog_component) {
                    this.set_state_property({
                        property: 'app_dialog',
                        data: null,
                    })
                }
            },
            immediate: true,
        },
        dialog_component(val) {
            if (!val) return
            this.dialog = true
        },
    },
    methods: {
        ...mapActions(['set_state_property']),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
