<template>
    <div :style="css_props">
        <div style="position: relative">
            <v-badge
                :value="new_medal_received"
                :color="current_best_new_medal"
                :offset-x="$vuetify.breakpoint.smAndDown ? '15px' : '10px'"
                :offset-y="$vuetify.breakpoint.smAndDown ? '15px' : '10px'"
                class="pulse_animation"
                overlap
                dot
            >
                <v-btn
                    @click="open_achievements"
                    :x-large="$vuetify.breakpoint.mdAndUp"
                    class="mr-0"
                    icon
                >
                    <v-icon
                        v-text="'mdi-trophy'"
                        :large="$vuetify.breakpoint.mdAndUp"
                    />
                </v-btn>
            </v-badge>

            <v-expand-transition>
                <v-card
                    v-show="display_new_medal"
                    style="position: absolute; right: -6px; top: calc(100% + 8px)"
                    min-width="250px"
                    max-width="250px"
                    light
                >
                    <v-card-actions :key="new_medal_type">
                        <v-icon
                            v-text="'mdi-trophy'"
                            :color="new_medal_type"
                            class="mr-4"
                            large
                        />
                        <div
                            v-text="translate(new_medal_type) + '!'"
                            class="title"
                        />
                        <v-spacer />
                        <v-btn
                            @click="display_new_medal = false"
                            icon
                        >
                            <v-icon v-text="'mdi-close'" />
                        </v-btn>
                    </v-card-actions>
                    <v-img
                        v-if="new_medal_type === 'gold'"
                        src="@/assets/response_2_happy.gif"
                        height="175px"
                    />
                </v-card>
            </v-expand-transition>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { locales_mixin } from '@/mixins/services/locales_mixin'
import { progress_mixin } from '@/mixins/components/progress_mixin'
import { program_mixin } from '@/mixins/components/program_mixin'

export default {
    name: 'AchievementButton',
    props: {
        value: {
            type: [String, Object, Number, Boolean, Array],
        },
    },
    mixins: [locales_mixin, progress_mixin, program_mixin],
    components: {},
    data() {
        return {
            new_medal_received: false,
            current_best_new_medal: 'none',

            display_new_medal: false,
            new_medal_type: 'none',
        }
    },
    computed: {
        css_props() {
            const pulse_color = this.$vuetify.theme.currentTheme[this.current_best_new_medal]
            if (!pulse_color) return {}

            return {
                '--pulse-color': pulse_color + 'FF',
                '--pulse-color-07-opacity': pulse_color + 'B3',
                '--pulse-color-00-opacity': pulse_color + '00',
            }
        },
        total_gold_grades() {
            if (!this.user_progress) return null
            let count = 0

            const course_levels = this.user_progress.course_levels || []
            for (let course_level of course_levels) {
                if (course_level.level !== 'gold') continue
                count += 1
            }

            const other_levels = ['cv_level', 'interview_level', 'job_application_level']
            for (const level of other_levels) {
                if (this.user_progress[level] === 'gold') {
                    count += 1
                }
            }

            return count
        },
        total_silver_grades() {
            if (!this.user_progress) return null
            let count = 0

            const course_levels = this.user_progress.course_levels || []
            for (let course_level of course_levels) {
                if (course_level.level !== 'silver') continue
                count += 1
            }

            const other_levels = ['cv_level', 'interview_level', 'job_application_level']
            for (const level of other_levels) {
                if (this.user_progress[level] === 'silver') {
                    count += 1
                }
            }

            return count
        },
        total_bronze_grades() {
            if (!this.user_progress) return null
            let count = 0

            const course_levels = this.user_progress.course_levels || []
            for (let course_level of course_levels) {
                if (course_level.level !== 'bronze') continue
                count += 1
            }

            const other_levels = ['cv_level', 'interview_level', 'job_application_level']
            for (const level of other_levels) {
                if (this.user_progress[level] === 'bronze') {
                    count += 1
                }
            }

            return count
        },

        ...mapGetters(['modules_by_program']),
        ...mapState(['user_progress', 'total_courses_per_module', 'programs', 'disable_achievement_popup']),
    },
    watch: {
        display_new_medal(val) {
            if (val) {
                setTimeout(() => {
                    this.display_new_medal = false
                    setTimeout(() => {
                        this.new_medal_type = 'none'
                    }, 500)
                }, 6 * 1000)
            }
        },
        disable_achievement_popup(value) {
            if (!value && this.current_best_new_medal !== 'none') {
                this.new_medal_received = true
                this.display_new_medal = true
            }
        },
        total_gold_grades(val, old_val) {
            if (val && old_val !== null) {
                if (val > old_val) {
                    this.notify_new_medal('gold')
                }
            }
        },
        total_silver_grades(val, old_val) {
            if (val && old_val !== null) {
                if (val > old_val) {
                    this.notify_new_medal('silver')
                }
            }
        },
        total_bronze_grades(val, old_val) {
            if (val && old_val !== null) {
                if (val > old_val) {
                    this.notify_new_medal('bronze')
                }
            }
        },
    },
    methods: {
        open_achievements() {
            this.reset_notifications()
            this.set_state_property({
                property: 'right_navigation_component',
                data: 'UserAchievements',
            })
        },
        notify_new_medal(type) {
            const config = {
                gold: 3,
                silver: 2,
                bronze: 1,
                none: 0,
            }

            this.new_medal_type = type
            if (config[type] > config[this.current_best_new_medal]) {
                this.current_best_new_medal = type
            }

            if (!this.disable_achievement_popup) {
                this.new_medal_received = true
                this.display_new_medal = true
            }
        },
        reset_notifications() {
            this.new_medal_received = false
            this.display_new_medal = false
            setTimeout(() => {
                this.new_medal_type = 'none'
                this.current_best_new_medal = 'none'
            }, 250)
        },

        ...mapActions(['set_state_property']),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped lang="sass">
.pulse_animation::v-deep .v-badge__wrapper .v-badge__badge
    transform: scale(1)
    animation: pulse 2s infinite


@keyframes pulse
    0%
        transform: scale(0.95)
        box-shadow: 0 0 0 0 var(--pulse-color-07-opacity)
    //rgba(255, 255, 255, 0.7)
    70%
        transform: scale(1)
        box-shadow: 0 0 0 10px var(--pulse-color-00-opacity)
    //rgba(255, 255, 255, 0)
    100%
        transform: scale(0.95)
        box-shadow: 0 0 0 0 var(--pulse-color-00-opacity)
//rgba(255, 255, 255, 0)
</style>
