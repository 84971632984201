import { mapActions, mapGetters, mapState } from 'vuex'
import { api_mixin } from '@/mixins/services/api_mixin'
import { date_mixin } from '@/mixins/services/date_mixin'
import { format } from 'date-fns'

export const statistics_mixin = {
    mixins: [api_mixin, date_mixin],
    data() {
        return {}
    },
    computed: {
        ...mapGetters([]),
        ...mapState(['user_statistic_id', 'user_session_data', 'user_extended_data', 'trophy_levels', 'content_types']),
    },
    methods: {
        app_init_statistics_tracking() {
            this._reset_user_session()

            this._save_user_statistics_logged_on()
        },
        app_save_user_statistics_gathered_data() {
            const attribute_payloads = this._user_statistics_attribute_payloads()
            this._save_user_statistics(attribute_payloads)
            this._reset_user_session()
        },
        async app_load_user_statistics_current_week() {
            const now = new Date()
            const year = now.getFullYear()
            const calendar_week = this.calendary_week(now)

            const response = await this.api_get({
                url: 'x-u2work--statistics-on-citizens',
                params: {
                    where: {
                        year: year,
                        week: calendar_week,
                        citizen: this.user_extended_data.user,
                    },
                    projection: {
                        _id: 1,
                    },
                },
            })

            let user_statistics_id = response.data._items?.[0]?._id
            if (!user_statistics_id) {
                user_statistics_id = await this._create_user_statistics_current_week(year, calendar_week)
            }

            this.set_state_property({
                property: 'user_statistic_id',
                data: user_statistics_id,
            })

            return user_statistics_id
        },
        _increase_clicks_statistics(number_of_clicks) {
            const user_session_data_copy = structuredClone(this.user_session_data)
            user_session_data_copy.clicks += number_of_clicks
            this.set_state_property({
                property: 'user_session_data',
                data: user_session_data_copy,
            })
        },
        add_new_medal_statistic(content_type, trophy_level, course) {
            if (!this.trophy_levels.includes(trophy_level)) {
                return console.error(`Trophy level '${trophy_level}' is not valid. Use one of '${this.trophy_levels}'`)
            }
            if (!this.content_types.includes(content_type)) {
                return console.error(`Content type '${content_type}' is not valid. Use one of '${this.content_types}'`)
            }

            const key = `${content_type}_${trophy_level}`

            const user_session_data_copy = structuredClone(this.user_session_data)

            if (course) {
                user_session_data_copy.new_medals[key] += 1
            } else {
                user_session_data_copy.new_medals[key] = 1
            }

            this.set_state_property({
                property: 'user_session_data',
                data: user_session_data_copy,
            })
        },
        async _create_user_statistics_current_week(year, calendar_week) {
            let new_user_statistics = {
                year: year,
                week: calendar_week,
                citizen: this.user_extended_data.user,
                activity: {
                    logged_on: 0,
                    clicks: 0,
                    minutes_spent_in_application: 0,
                },
                medals: this._new_medals_user_session_data(),
            }
            const response = await this.api_post({
                url: 'x-u2work--statistics-on-citizens',
                data: new_user_statistics,
            })

            return response.data._id
        },
        _save_user_statistics_logged_on() {
            const today = format(new Date(), 'EEEE').toLowerCase()
            this._save_user_statistics([
                {
                    attribute_path: 'activity.logged_on',
                    increase_with_integer: 1,
                },
                {
                    attribute_path: `daily_activity.${today}.logged_on`,
                    increase_with_integer: 1,
                },
            ])
        },

        _save_user_statistics(attribute_payloads) {
            for (let attribute_payload of attribute_payloads) {
                const base_payload = {
                    custom_endpoint: this._get_statistics_on_citizen_endpoint_id(),
                    document_id: this.user_statistic_id,
                    user: this.user_extended_data.user,
                }
                const payload = Object.assign({}, base_payload, attribute_payload)

                this.api_post({
                    url: 'custom-endpoint-increases',
                    data: payload,
                })
            }
        },
        _get_statistics_on_citizen_endpoint_id() {
            const statistics_on_citizen_endpoint_id = {
                test: '6388b3c7bbadf3e92ab8a10b',
                prod: '6405f3edcbc687ca64733568',
            }

            if (window.location.host.includes('localhost')) {
                return statistics_on_citizen_endpoint_id['test']
            } else if (window.location.host.includes('test')) {
                return statistics_on_citizen_endpoint_id['test']
            } else {
                return statistics_on_citizen_endpoint_id['prod']
            }
        },
        _reset_user_session() {
            this.set_state_property({
                property: 'user_session_data',
                data: {
                    tracked_from: new Date().getTime(),
                    clicks: 0,
                    new_medals: this._new_medals_user_session_data(),
                },
            })
        },
        _user_statistics_attribute_payloads() {
            const today = format(new Date(), 'EEEE').toLowerCase()
            const minute_in_millis = 1000 * 60
            const minutes_spent_in_application = Math.round(
                (new Date().getTime() - this.user_session_data.tracked_from) / minute_in_millis
            )

            const increase_attribute_payloads = [
                {
                    attribute_path: 'activity.minutes_spent_in_application',
                    increase_with_integer: minutes_spent_in_application,
                },
                {
                    attribute_path: `daily_activity.${today}.minutes_spent_in_application`,
                    increase_with_integer: minutes_spent_in_application,
                },
            ]

            if (this.user_session_data.clicks) {
                increase_attribute_payloads.push(
                    {
                        attribute_path: 'activity.clicks',
                        increase_with_integer: this.user_session_data.clicks,
                    },
                    {
                        attribute_path: `daily_activity.${today}.clicks`,
                        increase_with_integer: this.user_session_data.clicks,
                    }
                )
            }

            Object.entries(this.user_session_data.new_medals).forEach(([k, v]) => {
                if (v) {
                    increase_attribute_payloads.push({
                        attribute_path: `medals.${k}`,
                        increase_with_integer: v,
                    })
                }
            })

            return increase_attribute_payloads
        },
        _new_medals_user_session_data() {
            return this.content_types.reduce((accumulator, curr) => {
                this.trophy_levels.forEach((medal_type) => {
                    accumulator[`${curr}_${medal_type}`] = 0
                })
                return accumulator
            }, {})
        },
        ...mapActions(['set_state_property']),
    },
}
