<template>
    <v-row no-gutters>
        <v-col
            v-for="(program, index) in programs"
            :key="program._id"
            xl="3"
            lg="4"
            md="6"
            sm="12"
            cols="12"
            class="mb-2"
        >
            <NavigationCard
                :value="program"
                :to="{
                    name: 'Modules',
                    query: {
                        p: program._id,
                    },
                }"
                type="program"
                :class="(index + 1) % columns !== 0 ? 'mr-2' : 'mr-0'"
            />
        </v-col>

        <v-col
            xl="3"
            lg="4"
            md="6"
            sm="12"
            cols="12"
            class="mb-2"
            v-for="(card, index) in navigation_cards"
            :key="index"
        >
            <NavigationCard
                :value="card.value"
                :to="card.to"
                type="custom"
                :custom_media_file_path="card.custom_media_file_path"
                :class="(index + 1) % columns !== 0 ? 'mr-2' : 'mr-0'"
                hide_progress
            />
        </v-col>
    </v-row>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { api_mixin } from '@/mixins/services/api_mixin'
import NavigationCard from '@/components/navigation_card'
import { program_mixin } from '@/mixins/components/program_mixin'
import { module_mixin } from '@/mixins/components/module_mixin'

export default {
    name: 'Home',
    props: {},
    mixins: [api_mixin, program_mixin, module_mixin],
    components: { NavigationCard },
    data() {
        return {
            navigation_cards: [
                {
                    value: {
                        name: 'games',
                        description: 'game_description',
                    },
                    to: { name: 'Games' },
                    custom_media_file_path: 'games.gif',
                },
                {
                    value: {
                        name: 'tools',
                        description: 'tools_description',
                    },
                    to: { name: 'Tools' },
                    custom_media_file_path: 'tools.gif',
                },
            ],
        }
    },
    computed: {
        columns() {
            if (this.$vuetify.breakpoint.xl) {
                return 4
            }
            if (this.$vuetify.breakpoint.lg) {
                return 3
            }
            if (this.$vuetify.breakpoint.md) {
                return 2
            }
            return 1
        },
        ...mapGetters([]),
        ...mapState(['programs', 'total_programs']),
    },
    watch: {},
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.load_existing_module_images()
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
