import { mapActions, mapGetters, mapState } from 'vuex'

export const utils_mixin = {
    data() {
        return {}
    },
    computed: {
        ...mapGetters({}),
        ...mapState({}),
    },
    methods: {
        get_url_query_parameter(name, url = '') {
            if (!url) {
                url = window.location.href
            }
            let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url)

            if (!results || !results[1]) return ''

            return decodeURIComponent(results[2].replace(/\+/g, ' '))
        },
        ...mapActions([]),
    },
}
