<template>
    <v-card
        @click="go_to"
        height="100%"
        :loading="!media_file"
    >
        <div v-if="!dense">
            <v-img
                v-if="media_file"
                :src="media_file.url"
                height="150px"
            />
            <div
                v-else
                style="height: 150px"
            />
        </div>

        <v-card-title>
            <v-row
                no-gutters
                justify="space-between"
                align="start"
            >
                <v-col cols="9">
                    {{ name }}
                </v-col>
                <v-tooltip
                    v-if="!hide_progress"
                    bottom
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-progress-circular
                            v-bind="attrs"
                            v-on="on"
                            :rotate="-90"
                            :size="50"
                            :width="5"
                            :value="progress"
                            :color="grade_color(progress)"
                            class="mr-3"
                        >
                            <div
                                v-if="current_progress_display === 'percent'"
                                v-text="`${Math.floor(progress) || 0}%`"
                                class="subtitle-2 font-weight-bold"
                            />
                            <v-icon
                                v-else-if="current_config.progress.display === 'icon' && progress"
                                v-text="current_config.progress.icon"
                                :color="grade_color(progress)"
                            />
                            <div v-else-if="current_progress_display === 'icon_and_percent' && progress">
                                <v-row
                                    no-gutters
                                    justify="center"
                                >
                                    <v-icon
                                        v-text="current_config.progress.icon"
                                        :color="grade_color(progress)"
                                        x-small
                                    />
                                </v-row>
                                <v-row
                                    no-gutters
                                    justify="center"
                                >
                                    <div
                                        v-text="`${Math.floor(progress) || 0}%`"
                                        class="caption font-weight-bold"
                                    />
                                </v-row>
                            </div>
                        </v-progress-circular>
                    </template>
                    <span>{{ $t('progress') }}</span>
                </v-tooltip>
            </v-row>
        </v-card-title>

        <v-card-subtitle
            v-if="value.description"
            v-text="description"
        />
    </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { locales_mixin } from '@/mixins/services/locales_mixin'
import { api_mixin } from '@/mixins/services/api_mixin'
import { course_mixin } from '@/mixins/components/course_mixin'
import { program_mixin } from '@/mixins/components/program_mixin'
import { module_mixin } from '@/mixins/components/module_mixin'
import { progress_mixin } from '@/mixins/components/progress_mixin'

export default {
    name: 'NavigationCard',
    props: {
        value: {
            type: Object,
            required: true,
        },
        to: {
            type: Object,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        custom_media_file_path: {
            type: String,
            default: '',
        },
        hide_progress: {
            type: Boolean,
            default: false,
        },
        dense: Boolean,
    },
    mixins: [api_mixin, locales_mixin, course_mixin, program_mixin, module_mixin, progress_mixin],
    components: {},
    data() {
        return {
            navigation_card_config: {
                program: {
                    media_file_function: this.get_program_image,
                    progress: {
                        function: this.get_program_progress,
                        display: 'percent',
                    },
                },
                module: {
                    media_file_function: this.get_module_image,
                    progress: {
                        function: this.get_module_progress,
                        display: 'percent',
                    },
                },
                course: {
                    media_file_function: this.get_course_image,
                    progress: {
                        function: this.get_course_progress,
                        display: 'icon',
                        icon: 'mdi-trophy',
                    },
                },
                game: {
                    media_file_function: this.get_game_image,
                    progress: {
                        function: () => {
                            return 0
                        },
                        display: 'percent',
                    },
                },
                cherry: {
                    media_file_function: this.get_cherry_image,
                    progress: {
                        function: () => {
                            return 0
                        },
                        display: 'percent',
                    },
                },
                custom: {
                    media_file_function: this.get_custom_image,
                    progress: {
                        function: () => {
                            return 0
                        },
                        display: 'percent',
                    },
                },
            },
            progress_display: {},
            media_file: null,
            progress: 0,
        }
    },
    computed: {
        current_progress_display() {
            return this.navigation_card_config[this.type].progress.display
        },
        current_config() {
            return this.navigation_card_config[this.type]
        },
        name() {
            if (typeof this.value['name'] === 'string') {
                return this.translate(this.value['name'])
            } else {
                return this.translate_dict(this.value['name'])
            }
        },
        description() {
            if (typeof this.value['description'] === 'string') {
                return this.translate(this.value['description'])
            } else {
                return this.translate_dict(this.value['description'])
            }
        },
        ...mapGetters([]),
        ...mapState(['user_extended_data', 'user_progress']),
    },
    watch: {
        user_progress: {
            async handler(val) {
                if (!val) return
                this.$nextTick(async () => {
                    this.progress = await this.current_config.progress.function(this.value._id)
                })
            },
            immediate: true,
        },
    },
    methods: {
        go_to() {
            if (Object.keys(this.to).length) this.$router.push(this.to)
        },
        async get_game_image() {
            return await this.get_media_file(
                `x-u2work--${this.value.game}-games`,
                this.value._id,
                this.value?.image?.id
            )
        },
        async get_cherry_image() {
            return await this.get_media_file('x-u2work--cherry-picks', this.value._id, this.value?.image?.id)
        },
        get_custom_image() {
            return { url: require(`@/assets/${this.custom_media_file_path}`) }
        },
        ...mapActions(['update_key_in_state_property']),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    async mounted() {
        this.media_file = await this.current_config.media_file_function(this.value._id, this.value)
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
