import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '@/views/Login'

import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/statistics',
        redirect: (to) => {
            const { search } = to.query
            let url = ''
            if (window.location.host.includes('localhost')) {
                url = 'http://localhost:8090/users'
            } else if (window.location.host.includes('test')) {
                url = 'https://coach.u2work.se/users'
            } else {
                url = 'https://coach.u2work.se/users'
            }
            if (search) {
                url += `?search=${search}`
            }
            window.open(url, '_self')
        },
    },
    {
        path: '/',
        alias: ['/programs'],
        name: 'Home',
        component: Home,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/program/modules',
        alias: ['/modules'],
        name: 'Modules',
        component: () => import(/*webpackChunkName "modules"*/ '@/views/Modules'),
        props: (route) => ({
            program_id: route.query.p,
        }),
    },
    {
        path: '/program/module/courses',
        alias: ['/module/courses', '/courses'],
        name: 'Courses',
        component: () => import(/*webpackChunkName "courses"*/ '@/views/Courses'),
        props: (route) => ({
            module_id: route.query.m,
        }),
    },
    {
        path: '/program/module/course/:course_id',
        alias: ['/module/course/:course_id', '/course/:course_id'],
        name: 'Course',
        component: () => import(/*webpackChunkName "courses"*/ '@/views/Course'),
        props: (route) => ({
            course_id: route.params.course_id,
            course: route.params.course || null,
            step: parseInt(route?.query?.s || '0'),
            sub_step: parseInt(route?.query?.sub_s || '0'),
        }),
    },
    {
        path: '/games',
        name: 'Games',
        component: () => import(/*webpackChunkName "Games"*/ '@/views/Games/Games'),
    },
    {
        path: '/games/:game',
        name: 'GameOverview',
        component: () => import(/*webpackChunkName "GameOverview"*/ '@/views/Games/GameOverview'),
    },
    {
        path: '/games/:game/help',
        name: 'GameHelp',
        component: () => import(/*webpackChunkName "GameHelp"*/ '@/views/Games/GameHelp'),
    },
    {
        path: '/games/:game/highscore',
        name: 'GameHighscore',
        component: () => import(/*webpackChunkName "GameHighscore"*/ '@/views/Games/GameHighscore'),
    },
    {
        path: '/games/:game/play',
        name: 'GamePlay',
        component: () => import(/*webpackChunkName "GamePlay"*/ '@/views/Games/GamePlay'),
    },
    {
        path: '/tools',
        name: 'Tools',
        component: () => import(/*webpackChunkName "Tools"*/ '@/views/Tools/Tools'),
    },
    {
        path: '/tools/:tool',
        name: 'Tool',
        component: () => import(/*webpackChunkName "Tool"*/ '@/views/Tools/Tool'),
    },
    {
        path: '/tools/:tool/:id',
        name: 'Detail',
        component: () => import(/*webpackChunkName "Tool"*/ '@/views/Tools/Detail'),
    },
    {
        path: '/surveys',
        name: 'Surveys',
        component: () => import(/*webpackChunkName "Surveys"*/ '@/views/Survey/Surveys'),
    },
    {
        path: '/surveys/:survey_id',
        name: 'Survey',
        component: () => import(/*webpackChunkName "Survey"*/ '@/views/Survey/Survey'),
    },

    /* PageNotFound Must be last!!!*/
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: () => import(/*webpackChunkName "page_not_found"*/ '../views/PageNotFound'),
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
})

router.beforeEach((to, from, next) => {
    if (store.state.user_progress) {
        const latest_activity = new Date(store.state.user_progress.latest_activity).getTime()
        if (Date.now() - latest_activity > 1000 * 60 * 60) {
            const user_session_data_copy = structuredClone(store.state.user_progress)
            user_session_data_copy.latest_activity = new Date().toUTCString()
            store.commit('mutate', {
                property: 'user_progress',
                data: user_session_data_copy,
            })
        }
    }
    next()
})

export default router
