<template>
    <v-dialog
        :value="terms_and_condition_dialog"
        persistent
    >
        <v-card tile>
            <v-card-title
                class="ma-0 pa-0"
                style="position: sticky; top: 1rem; right: 1rem"
            >
                <v-spacer />
                <v-btn
                    @click="close"
                    class="mr-4"
                    fab
                    small
                    color="white"
                >
                    <v-icon v-text="'mdi-close'" />
                </v-btn>
            </v-card-title>
            <v-card-title>
                <v-row
                    no-gutters
                    justify="space-between"
                    align="center"
                >
                    {{ terms_and_condition_dialog === 'gdpr' ? translate('gdpr') : translate('terms') }}
                </v-row>
            </v-card-title>
            <v-card-subtitle class="pt-2">
                {{
                    terms_and_condition_dialog === 'gdpr'
                        ? translate('gdpr_description')
                        : translate('terms_description')
                }}
            </v-card-subtitle>
            <component
                v-for="(text, i) in texts"
                :key="i"
                :is="text.component"
                :class="{
                    'font-weight-bold': text.header,
                    'pb-2': text.header,
                }"
            >
                <ul v-if="text.list">
                    <li
                        v-for="(item, i) in text.items"
                        :key="i"
                    >
                        {{ translate_dict(item) }}
                    </li>
                </ul>
                <span v-else>
                    {{ translate_dict(text.value) }}
                </span>
            </component>
            <v-card-text class="text-center mt-8">
                TCB People & Organisation AB<br />
                Org.nr 559215-9940<br />
                <a href="mailto:info@tcbab.se">info@tcbab.se</a>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { VCardSubtitle, VCardText } from 'vuetify/lib'
import { locales_mixin } from '@/mixins/services/locales_mixin'

export default {
    name: 'TermsAndConditionsDialog',
    props: {},
    mixins: [locales_mixin],
    components: {
        VCardText,
        VCardSubtitle,
    },
    data() {
        return {
            texts: [],
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState(['terms_and_condition_dialog']),
    },
    watch: {
        async terms_and_condition_dialog(value) {
            if (value) {
                this.texts = []
                const texts = await import(`@/data/${this.terms_and_condition_dialog}.json`)
                for (const text of texts.default) {
                    if (text.header) {
                        this.texts.push({
                            header: true,
                            value: text.header,
                            component: VCardText,
                        })
                    } else if (text.list) {
                        this.texts.push({
                            list: true,
                            items: text.list,
                            component: VCardSubtitle,
                        })
                    } else {
                        this.texts.push({
                            value: text.text,
                            component: VCardSubtitle,
                        })
                    }
                }
            }
        },
    },
    methods: {
        close() {
            this.set_state_property({
                property: 'terms_and_condition_dialog',
                data: '',
            })
        },
        ...mapActions(['set_state_property']),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped lang="sass"></style>
