import { isValid, format, getWeek } from 'date-fns'

export const date_mixin = {
    methods: {
        format_date_time(value) {
            const date = new Date(value)
            if (!isValid(date)) return value
            return format(date, 'yyyy-MM-dd HH:mm')
        },
        format_date(value) {
            const date = new Date(value)
            if (!isValid(date)) return value
            return format(date, 'yyyy-MM-dd')
        },
        format_time(value) {
            const hours = Math.floor(value / 3600)
            const minutes = Math.floor((value - hours * 3600) / 60)
            const seconds = value - hours * 3600 - minutes * 60
            if (hours) {
                return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
            } else {
                return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
            }
        },
        calendary_week(date) {
            const year = date.getFullYear()

            return getWeek(date, {
                weekStartsOn: 1,
                firstWeekContainsDate: new Date(year, 0, 1).getDay(),
            })
        },
    },
}
