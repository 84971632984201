<template>
    <v-snackbars :objects.sync="internal_value">
        <template v-slot:action="{ close }">
            <v-btn
                @click="close()"
                color="transparent"
                depressed
                fab
                small
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbars>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import VSnackbars from 'v-snackbars'
import { locales_mixin } from '@/mixins/services/locales_mixin'

export default {
    name: 'TheSnackbars',
    props: {},
    mixins: [locales_mixin],
    components: {
        VSnackbars,
    },
    data() {
        return {
            internal_value: [],
        }
    },
    computed: {
        ...mapGetters([]),
        ...mapState(['messages']),
    },
    watch: {
        messages() {
            const message = this.messages[this.messages.length - 1]
            message['message'] = this.translate(message['message'])
            this.internal_value.push(message)
        },
    },
    methods: {
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
