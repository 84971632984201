import { mapActions, mapGetters, mapState } from 'vuex'
import { api_mixin } from '@/mixins/services/api_mixin'

export const module_mixin = {
    mixins: [api_mixin],
    computed: {
        ...mapGetters(['modules_by_program']),
        ...mapState(['modules', 'total_modules_per_program']),
    },
    methods: {
        async load_modules(program_id) {
            const where = {
                _id: {
                    $nin: this.modules.map((x) => x['_id']),
                },
            }
            if (program_id) {
                where['program'] = program_id
            }

            const modules = await this.api_get_all_items('x-u2work--modules', 'sort_value,-_updated', where)
            this.concatenate_state_property({
                property: 'modules',
                data: modules,
            })
        },
        load_existing_module_images() {
            for (let module of this.modules) {
                this.get_media_file('x-u2work--modules', module._id, module?.image?.id)
            }
        },
        async get_module(module_id, program_id = null) {
            if (!module_id) return null

            let module_list = this.modules
            if (this.modules_by_program[program_id]?.length) {
                module_list = this.modules_by_program[program_id]
            }

            for (let i = 0; i < module_list.length; i++) {
                const module = module_list[i]
                if (module._id === module_id) {
                    return module
                }
            }

            const response = await this.api_get({
                url: `x-u2work--modules/${module_id}`,
            })
            const module = response.data
            if (!module) return null

            this.concatenate_state_property({
                property: 'modules',
                data: [module],
            })
            return module
        },
        async get_module_image(module_id, module = null) {
            if (!module) {
                module = this.get_module(module_id)
            }
            let pass_response = await this.api_get({
                url: 'x-u2work--survey-final-scores',
                params: {
                    where: {
                        survey_module: module_id,
                        user: this.user_extended_data.user,
                        grade: { $in: ['pass', 'pass_with_excellence'] },
                    },
                    sort: '-_created',
                    projection: { info: 1 },
                    page: 1,
                    max_results: 1,
                },
            })
            if (pass_response.data._meta.total > 0) {
                return await this.get_media_file('x-u2work--modules', module_id, module?.exam_image?.id)
            }

            return await this.get_media_file('x-u2work--modules', module_id, module?.image?.id)
        },
        async get_total_modules_for_program(program_id) {
            if (this.total_modules_per_program[program_id]) {
                return this.total_modules_per_program[program_id]
            }

            const response = await this.api_get({
                url: `x-u2work--modules`,
                params: {
                    where: {
                        program: program_id,
                    },
                    projection: {
                        _id: 1,
                    },
                    max_results: 1,
                },
            })

            this.update_key_in_state_property({
                property: 'total_modules_per_program',
                data: response.data._meta.total,
                key: program_id,
            })

            return response.data._meta.total
        },
        ...mapActions(['concatenate_state_property', 'update_key_in_state_property']),
    },
}
