<template>
    <v-app-bar
        ref="app_bar"
        :scroll-threshold="50"
        color="primary accent-4"
        hide-on-scroll
        app
        dark
    >
        <v-toolbar-title class="align-center">
            <v-img
                alt="Vue logo"
                src="@/assets/logo_white.png"
                :width="$vuetify.breakpoint.mdAndUp ? '140px' : '100px'"
                @click="go_to_home"
                style="cursor: pointer"
                class="ml-2"
            />
        </v-toolbar-title>

        <v-spacer />

        <AchievementButton />

        <HelpButton />

        <v-badge
            offset-x="25"
            offset-y="25"
            light
            bottom
            overlap
            bordered
        >
            <template #badge>
                <div style="position: relative; height: 12px; pointer-events: none">
                    <flag
                        v-if="locale_to_iso_3166_county_code_mapping_table[locale] !== '-'"
                        :iso="locale_to_iso_3166_county_code_mapping_table[locale]"
                        style="
                            width: 13px;
                            height: 13px;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        "
                    />
                    <v-icon
                        v-else
                        v-text="'fa-flag'"
                        dark
                        x-small
                    />
                </div>
            </template>
            <template #default>
                <v-btn
                    @click="open_user_settings"
                    :x-large="$vuetify.breakpoint.mdAndUp"
                    style="pointer-events: all"
                    dark
                    icon
                >
                    <v-icon
                        v-text="'mdi-account'"
                        :large="$vuetify.breakpoint.mdAndUp"
                    />
                </v-btn>
            </template>
        </v-badge>
    </v-app-bar>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { locales_mixin } from '@/mixins/services/locales_mixin'
import AchievementButton from '@/components/the_header/achievement_button'
import HelpButton from '@/components/the_header/help_button'

export default {
    name: 'TheHeader',
    props: {
        value: {
            type: [String, Object, Number, Boolean, Array],
        },
    },
    mixins: [locales_mixin],
    components: { HelpButton, AchievementButton },
    data() {
        return {
            internal_value: undefined,
        }
    },
    computed: {
        locale() {
            return this.user_progress?.selected_locale || 'sv'
        },
        ...mapGetters([]),
        ...mapState(['user_progress']),
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
        internal_value: {
            handler(val, old_val) {
                if (old_val === undefined) return
                this.$emit('input', val)
            },
            deep: true,
        },
    },
    methods: {
        open_user_settings() {
            this.set_state_property({
                property: 'right_navigation_component',
                data: 'UserSettings',
            })
        },
        go_to_home() {
            if (this.$route.name === 'Home') {
                return this.$router.go(0)
            }
            this.$router.push({ name: 'Home' })
        },
        ...mapActions(['set_state_property']),
    },
    beforeCreate() {},
    created() {
        this.internal_value = this.value
    },
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped lang="sass">
::v-deep .v-badge__badge
    pointer-events: none
</style>
