<template>
    <v-navigation-drawer
        v-model="drawer"
        width="300px"
        style="height: 100%; overflow: hidden"
        temporary
        fixed
        right
    >
        <v-toolbar flat>
            <v-toolbar-title v-text="translate(titles[right_navigation_component])" />
            <v-spacer />
            <v-btn
                @click="drawer = false"
                icon
            >
                <v-icon v-text="'mdi-close'" />
            </v-btn>
        </v-toolbar>
        <v-divider />
        <component
            v-if="right_navigation_component"
            :is="right_navigation_component"
            @close="drawer = false"
        />
    </v-navigation-drawer>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { locales_mixin } from '@/mixins/services/locales_mixin'

export default {
    name: 'TheNavigationDrawer',
    props: {},
    mixins: [locales_mixin],
    components: {
        UserAchievements: () => import(/*webpackChunkName "UserAchievements"*/ '@/components/user_achievements/index'),
        UserSettings: () => import(/*webpackChunkName "UserSettings"*/ '@/components/user_settings/index'),
    },
    data() {
        return {
            drawer: false,
        }
    },
    computed: {
        titles() {
            return {
                UserAchievements: 'goal',
                UserSettings: this.user_extended_data?.user_name || '-',
                Help: 'help',
            }
        },
        ...mapGetters([]),
        ...mapState(['right_navigation_component', 'user_extended_data']),
    },
    watch: {
        drawer: {
            handler(val) {
                if (val === false && this.right_navigation_component) {
                    this.set_state_property({
                        property: 'right_navigation_component',
                        data: null,
                    })
                }
            },
            immediate: true,
        },
        right_navigation_component(val) {
            if (!val) return
            this.drawer = true
        },
    },
    methods: {
        ...mapActions(['set_state_property']),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
