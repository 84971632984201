import Vue from 'vue'
import Vuex from 'vuex'
import VueJwtDecode from 'vue-jwt-decode'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

function restore_state_function(key, storage) {
    let state = storage.getItem(key)
    if (!state || !state.length) return null

    state = JSON.parse(state)

    if (!state.expires || state.expires < Date.now()) {
        console.log('state has expired!!!!')
        return null
    }

    return state.value
}

function save_state_function(key, state, storage, duration_ms) {
    const now = Date.now()
    let expires = null
    let existing_state = storage.getItem(key)
    if (existing_state) {
        existing_state = JSON.parse(existing_state)
        expires = existing_state.expires > now ? existing_state.expires : null
    }

    if (!expires) {
        expires = now + duration_ms
    }

    const state_to_save = {
        value: state,
        expires: expires,
    }
    storage.setItem(key, JSON.stringify(state_to_save))
}

const vuex_persist_forever = new VuexPersistence({
    key: 'vuex_persist_forever',
    storage: window.localStorage,
    reducer: (state) => ({
        token: state.token,
        locale: state.locale,
        application: state.application,
        playback_speed: state.playback_speed,
    }),
})

const vuex_persist_one_day = new VuexPersistence({
    key: 'vuex_persist_one_day',
    storage: window.localStorage,
    reducer: (state) => ({
        total_programs: state.total_programs,
        total_modules: state.total_modules,
        total_courses: state.total_courses,
        total_interview_questions: state.total_interview_questions,

        total_modules_per_program: state.total_modules_per_program,
        total_courses_per_module: state.total_courses_per_module,
    }),
    saveState: (key, state, storage) => {
        const expires = 1000 * 60 * 60 * 24
        return save_state_function(key, state, storage, expires)
    },
    restoreState: restore_state_function,
})

const vuex_persist_48h = new VuexPersistence({
    key: 'vuex_persist_48h',
    storage: window.localStorage,
    reducer: (state) => ({
        media_files: state.media_files,
        programs: state.programs,
        modules: state.modules,
        courses: state.courses,
        slides: state.slides,
        interview_questions: state.interview_questions,
        games: state.games,
    }),
    saveState: (key, state, storage) => {
        const expires = 1000 * 60 * 60 * 48
        return save_state_function(key, state, storage, expires)
    },
    restoreState: restore_state_function,
})

export default new Vuex.Store({
    state: {
        token: null,
        token_has_expired: false,
        messages: [],
        terms_and_condition_dialog: '',

        /*User*/
        user_extended_data: null,
        user_progress: null,
        user_statistic_id: null,

        /*Total entries in database*/
        total_programs: 0,
        total_modules: 0,
        total_courses: 0,
        total_interview_questions: 0,

        total_modules_per_program: {},
        total_courses_per_module: {},

        /* Main content */
        programs: [],
        modules: [],
        courses: [],
        interview_questions: [],
        games: {},
        media_files: {},

        /* Medals and content types*/
        trophy_levels: ['bronze', 'silver', 'gold'],
        content_types: ['course', 'cv', 'job_application'],

        /*Sub content*/
        slides: [],

        /* Application */
        application: null,
        right_navigation_component: null,
        locale_translation: null,
        app_dialog: null /*See: TheAppDialog*/,
        playback_speed: 'default',
        disable_achievement_popup: false,

        /* Nudging */
        nudging: {},

        /* User session statistics*/
        user_session_data: {
            /* Initiated in statistics_mixin*/
            clicks: 0,
            tracked_from: null,
            new_medals: {},
        },

        /* Features */
        features: {
            cv: null,
            job_application: null,
            messages: null,
            calendar: null,
            video_meeting: null,
            statistics_organization: null,
            statistics_units: null,
            statistics_coach: null,
            coach_admin: null,
        },
    },
    getters: {
        modules_by_program: (state) => {
            const modules_by_program = {}
            for (let i = 0; i < state.modules.length; i++) {
                const module = state.modules[i]
                if (!module.program) {
                  continue
                }
                if (!modules_by_program[module.program]) {
                    modules_by_program[module.program] = []
                }
                modules_by_program[module.program].push(module)
            }
            return modules_by_program
        },
        courses_by_module: (state) => {
            const courses_by_module = {}
            for (let i = 0; i < state.courses.length; i++) {
                const course = state.courses[i]
                if (!course.module) {
                  continue
                }
                if (!courses_by_module[course.module]) {
                    courses_by_module[course.module] = []
                }
                courses_by_module[course.module].push(course)
            }
            return courses_by_module
        },
        decoded_token: (state) => {
            if (!state.token) {
              return {}
            }
            return VueJwtDecode.decode(state.token)
        },
    },
    mutations: {
        mutate(state, payload) {
            if (payload.module) {
                state[payload.module][payload.property] = payload.data
            } else {
                state[payload.property] = payload.data
            }
        },
        mutate_key(state, payload) {
            if (payload.module) {
                Vue.set(state[payload.module][payload.property], payload.key, payload.data)
            } else {
                Vue.set(state[payload.property], payload.key, payload.data)
            }
        },
        push(state, payload) {
            if (payload.module) {
                state[payload.module][payload.property].push(payload.data)
            } else {
                state[payload.property].push(payload.data)
            }
        },
        concatenate(state, payload) {
            if (payload.module) {
                state[payload.module][payload.property] = state[payload.module][payload.property].concat(payload.data)
            } else {
                payload.data.filter((x) => !state[payload.property].includes(x))
                state[payload.property] = state[payload.property].concat(payload.data)
            }
        },
    },
    actions: {
        set_state_property({ commit }, payload) {
            commit('mutate', {
                property: payload.property,
                data: payload.data,
                module: payload.module,
            })
        },
        patch_state_property({ commit }, payload) {
            Object.entries(payload.data).forEach(([key, data]) => {
                commit('mutate_key', {
                    property: payload.property,
                    data: data,
                    key: key,
                    module: payload.module,
                })
            })
        },
        update_key_in_state_property({ commit }, payload) {
            commit('mutate_key', {
                property: payload.property,
                data: payload.data,
                key: payload.key,
                module: payload.module,
            })
        },
        set_state_properties({ commit }, payloads) {
            payloads.forEach((payload) => {
                commit('mutate', {
                    property: payload.property,
                    data: payload.data,
                    module: payload.module,
                })
            })
        },
        push_state_property({ commit }, payload) {
            commit('push', {
                property: payload.property,
                data: payload.data,
                module: payload.module,
            })
        },
        concatenate_state_property({ commit }, payload) {
            commit('concatenate', {
                property: payload.property,
                data: payload.data,
                module: payload.module,
            })
        },
    },
    modules: {},
    plugins: [vuex_persist_forever.plugin, vuex_persist_one_day.plugin, vuex_persist_48h.plugin],
})
