<template>
    <div>
        <v-menu
            nudge-bottom="5"
            offset-y
            bottom
            left
        >
            <template #activator="{ on, attrs }">
                <v-btn
                    v-on="on"
                    v-bind="attrs"
                    :x-large="$vuetify.breakpoint.mdAndUp"
                    icon
                >
                    <v-icon
                        v-text="'mdi-help-circle-outline'"
                        :large="$vuetify.breakpoint.mdAndUp"
                    />
                </v-btn>
            </template>
            <template #default>
                <v-card width="425px">
                    <v-card-title v-text="translate('help')" />
                    <v-divider />
                    <v-list class="py-0">
                        <div
                            v-for="(item, index) in menu"
                            :key="index"
                        >
                            <v-list-item
                                @click="open(item)"
                                :disabled="item.disabled"
                                :two-line="item.two_line"
                                :three-line="item.three_line"
                            >
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.title" />
                                    <v-list-item-subtitle v-text="item.subtitle" />
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider v-if="item.divider" />
                        </div>
                    </v-list>
                </v-card>
            </template>
        </v-menu>

        <v-dialog
            v-model="display_dialog"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            width="720px"
        >
            <v-card
                v-if="selected_item"
                :height="selected_item.height || '86vh'"
                class="app-flex__container"
            >
                <v-card-title>
                    <v-row
                        no-gutters
                        align="center"
                        justify="space-between"
                    >
                        {{ selected_item.title }}
                        <v-btn
                            @click="display_dialog = false"
                            icon
                        >
                            <v-icon v-text="'mdi-close'" />
                        </v-btn>
                    </v-row>
                </v-card-title>
                <v-card-text v-text="selected_item.subtitle" />

                <v-card-text class="app-flex__content">
                    <component
                        :is="selected_item.component"
                        :value="selected_item"
                        :video_media_file="selected_item.video"
                        :poster_media_file="selected_item.poster"
                        :subtitle="selected_item.video_subtitle"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { locales_mixin } from '@/mixins/services/locales_mixin'

export default {
    name: 'TheHeaderHelpButton',
    props: {},
    mixins: [locales_mixin],
    components: {
        Video: () => import(/*webpackChunkName "Video"*/ '@/components/video'),
        Medals: () => import(/*webpackChunkName "Medals"*/ './Medals'),
    },
    data() {
        return {
            selected_item: null,
            display_dialog: false,
        }
    },
    computed: {
        menu() {
            return [
                {
                    component: 'Video',
                    title: this.translate('help_video'),
                    subtitle: this.translate('help_video_description'),
                    video: {
                        url: 'https://storage.googleapis.com/api-engine-online/u2work/content/u2work_help.mp4',
                    },
                    poster: {
                        url: require('@/assets/u2work_help.png'),
                    },
                    video_subtitle: 'u2work_navigering',
                    two_line: true,
                    height: '550x',
                },
                {
                    component: 'Video',
                    title: this.translate('help_subtitles_video'),
                    subtitle: this.translate('help_subtitles_video_description'),
                    video: {
                        url: 'https://storage.googleapis.com/api-engine-online/u2work/content/help_subtitles.mp4',
                    },
                    poster: {
                        url: require('@/assets/help_subtitles.png'),
                    },
                    video_subtitle: 'hjalp_undertexter',
                    two_line: true,
                    divider: true,
                    height: '550x',
                },
                {
                    component: 'Medals',
                    type: 'course_completion',
                    title: this.translate('course_completion'),
                    subtitle: this.translate('course_completion_description'),
                    two_line: this.$vuetify.breakpoint.mdAndUp,
                    three_line: this.$vuetify.breakpoint.smAndDown,
                },
                {
                    component: 'Medals',
                    type: 'interview_game',
                    title: this.translate('interview_game_completion'),
                    subtitle: this.translate('interview_game_completion_description'),
                    two_line: true,
                },
                {
                    component: 'Medals',
                    type: 'cv',
                    title: this.translate('cv_completion'),
                    subtitle: this.translate('cv_completion_description'),
                    three_line: true,
                },
                {
                    component: 'Medals',
                    type: 'job_application',
                    title: this.translate('application_completion'),
                    subtitle: this.translate('application_completion_description'),
                    three_line: true,
                },
            ]
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {
        display_dialog(val) {
            if (val === false) {
                this.selected_item = null
            }
        },
    },
    methods: {
        open(item) {
            this.selected_item = item

            this.display_dialog = true
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>
<style scoped></style>
