import { mapActions, mapGetters, mapState } from 'vuex'
import { api_mixin } from '@/mixins/services/api_mixin'

export const course_mixin = {
    mixins: [api_mixin],
    computed: {
        ...mapGetters(['courses_by_module']),
        ...mapState(['courses', 'total_courses_per_module', 'user_progress']),
    },
    methods: {
        async load_courses(module_id) {
            const where = {
                _id: {
                    $nin: this.courses.map((x) => x['_id']),
                },
            }
            if (module_id) {
                where['module'] = module_id
            }

            const courses = await this.api_get_all_items('x-u2work--courses', 'sort_value,-_updated', where)
            this.concatenate_state_property({
                property: 'courses',
                data: courses,
            })
        },
        load_existing_course_images(program_id = null) {
            for (let course of this.courses) {
                if (program_id && course.program !== program_id) {
                    continue
                }

                this.get_media_file('x-u2work--courses', course._id, course?.image?.id)
            }
        },
        load_first_slide_of_course(module_id = null) {
            for (let course of this.courses) {
                if (module_id && course.module !== module_id) {
                    continue
                }

                const first_content = course?.content?.[0]
                if (!first_content) {
                    continue
                }
                this.get_media_file('x-u2work--courses', course._id, first_content?.image?.id)
                this.get_media_file('x-u2work--courses', course._id, first_content?.video?.id)
                this.get_media_file('x-u2work--courses', course._id, first_content?.podcast?.id)
            }
        },
        load_course_content(course) {
            if (!course.content) return
            for (let content of course.content) {
                this.get_media_file('x-u2work--courses', course._id, content?.image?.id)
                this.get_media_file('x-u2work--courses', course._id, content?.video?.id)
                this.get_media_file('x-u2work--courses', course._id, content?.podcast?.id)
            }
        },
        load_slide_content(slide) {
            for (let page of slide.pages) {
                for (let object of page.objects) {
                    this.get_media_file('x-u2work--slides', slide._id, object?.media?.id)
                }
            }
        },
        async get_course(course_id, module_id = null) {
            if (!course_id) return null

            let course_list = this.courses
            if (this.courses_by_module[module_id]?.length) {
                course_list = this.courses_by_module[module_id]
            }

            for (let i = 0; i < course_list.length; i++) {
                const course = course_list[i]
                if (course._id === course_id) {
                    return course
                }
            }

            const response = await this.api_get({
                url: `x-u2work--courses/${course_id}`,
            })
            const course = response.data
            if (!course) return null

            this.concatenate_state_property({
                property: 'courses',
                data: [course],
            })
            return course
        },
        async get_course_image(course_id, course = null) {
            if (!course) {
                course = await this.get_course(course_id)
            }

            return await this.get_media_file('x-u2work--courses', course_id, course?.image?.id)
        },
        async get_total_courses_for_module(module_id) {
            if (this.total_courses_per_module[module_id]) {
                return this.total_courses_per_module[module_id]
            }

            const response = await this.api_get({
                url: `x-u2work--courses`,
                params: {
                    where: {
                        module: module_id,
                    },
                    projection: {
                        _id: 1,
                    },
                    max_results: 1,
                },
            })

            this.update_key_in_state_property({
                property: 'total_courses_per_module',
                data: response.data._meta.total,
                key: module_id,
            })

            return response.data._meta.total
        },

        ...mapActions(['concatenate_state_property', 'update_key_in_state_property']),
    },
}
