import { mapActions, mapGetters, mapState } from 'vuex'
import { api_mixin } from '@/mixins/services/api_mixin'

export const program_mixin = {
    mixins: [api_mixin],
    computed: {
        ...mapGetters([]),
        ...mapState(['programs', 'user_extended_data']),
    },
    methods: {
        async load_programs() {
            const where = {
                _id: {
                    $nin: this.programs.map((x) => x['_id']),
                },
            }
            let programs = []
            try {
                programs = await this.api_get_all_items('x-u2work--programs', 'sort_value,-_updated', where)
            } catch (error) {
                if (error.response) {
                    if (error.response.status === 419) {
                        await this.sleep(3000)
                        location.reload()
                    }
                }
            }
            this.concatenate_state_property({
                property: 'programs',
                data: programs,
            })
        },
        async get_program(program_id) {
            if (!program_id) return null

            for (let i = 0; i < this.programs.length; i++) {
                if (this.programs[i]._id === program_id) {
                    return this.programs[i]
                }
            }

            const response = await this.api_get({
                url: `x-u2work--programs/${program_id}`,
            })
            const program = response.data
            if (!program) return null

            this.concatenate_state_property({
                property: 'programs',
                data: [program],
            })
            return program
        },
        async get_program_image(program_id, program = null) {
            if (!program) {
                program = this.get_program(program_id)
            }

            return await this.get_media_file('x-u2work--programs', program_id, program?.image?.id)
        },
        ...mapActions(['concatenate_state_property']),
    },
}
