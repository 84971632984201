<template>
    <v-app
        v-scroll="on_scroll"
        :style="{ background }"
    >
        <TheSnackbars/>
        <TermsAndConditionsDialog/>
        <TheRightNavigationDrawer/>
        <TheAppDialog/>
        <TheHeader
            v-if="$route.name !== 'Login'"
            ref="the_header"
        />
        <v-main>
            <v-container fluid>
                <router-view v-if="user_authenticated || $route.name === 'Login'"/>
                <v-row
                    v-else
                    class="mt-12"
                    justify="center"
                    no-gutters
                >
                    <v-progress-circular
                        color="primary"
                        indeterminate
                    />
                </v-row>
            </v-container>
        </v-main>
        <TheFooter v-if="$route.name !== 'Login'"/>
    </v-app>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {scroll} from 'vuetify/lib/directives/scroll'
import {api_mixin} from '@/mixins/services/api_mixin'
import {utils_mixin} from '@/mixins/services/utils_mixin'
import TheHeader from '@/components/the_header'
import TheFooter from '@/components/the_footer'
import TheRightNavigationDrawer from '@/components/the_rigth_navigation_drawer'
import TheSnackbars from '@/components/the_snackbars'
import VueJwtDecode from 'vue-jwt-decode'
import {progress_mixin} from '@/mixins/components/progress_mixin'
import {module_mixin} from '@/mixins/components/module_mixin'
import {course_mixin} from '@/mixins/components/course_mixin'
import TermsAndConditionsDialog from '@/components/terms_and_conditions'
import TheAppDialog from '@/components/the_app_dialog/index.vue'
import {statistics_mixin} from '@/mixins/components/statistics_mixin'

export default {
    name: 'App',
    directives: {
        scroll,
    },
    mixins: [api_mixin, utils_mixin, progress_mixin, module_mixin, course_mixin, statistics_mixin],
    props: [],
    components: {
        TheAppDialog,
        TermsAndConditionsDialog,
        TheSnackbars,
        TheRightNavigationDrawer,
        TheFooter,
        TheHeader,
    },
    data() {
        return {
            statistics_clicks: 0,
            statistic_click_interval: null,
            save_statistics_interval: null,
        }
    },
    computed: {
        user_authenticated() {
            return this.token && this.user_extended_data && this.user_progress
        },
        locale() {
            return this.user_progress?.selected_locale || 'sv'
        },
        ...mapGetters({
            decoded_token: 'decoded_token',
        }),
        ...mapState([
            'token',
            'total_programs',
            'total_modules',
            'total_courses',
            'total_interview_questions',
            'user_extended_data',
            'user_progress',
            'locale_translation',
            'application',
            'user_session_data',
            'token_has_expired',
        ]),
        background() {
            if (this.$route.name === 'Login') {
                return this.$vuetify.theme.currentTheme.primary
            }

            return this.$vuetify.theme.currentTheme['background']
        },
    },
    watch: {
        user_extended_data: {
            handler(user_extended_data) {
                if (user_extended_data && this.$route.query['e']) {
                    const email = (
                        user_extended_data['user_details']['email'] || user_extended_data['user_details']['email_oauth']
                    ).toLowerCase()
                    const query_email = this.$route.query['e'].toLowerCase()
                    localStorage.email = query_email
                    if (email !== query_email) {
                        this.set_state_property({
                            property: 'token',
                            data: null,
                        })
                    }
                }
            },
            immediate: true,
        },
        token: {
            handler(val, old_val) {
                if (val && !old_val) {
                    this.load_and_validate_application()
                    this.load_programs()
                    this.load_modules()
                    this.load_courses()
                    if (!this.total_programs) {
                        this.load_total_number_of_programs()
                    }
                    if (!this.total_modules) {
                        this.load_total_number_of_modules()
                    }
                    if (!this.total_courses) {
                        this.load_total_number_of_courses()
                    }
                    this.load_total_number_of_interview_questions()

                    this.load_user_extended_data()
                        .then(this.load_user_progress)
                        .then(this.app_load_user_statistics_current_week)
                        .then(this.init_statistics_tracking)
                        .then(this.load_organization_features)
                        .then(this.load_nudging_settings)
                }
                if (!val && old_val) {
                    this.$router.push({
                        name: 'Login',
                    })
                }
            },
            immediate: true,
        },
        token_has_expired: {
            handler(val) {
                if (val === true) {
                    this.refresh_token()
                }
            },
            immediate: true,
        },
        user_progress: {
            handler(new_user_progress, old_user_progress) {
                if (!old_user_progress || !new_user_progress) return
                if (new_user_progress.latest_activity === old_user_progress.latest_activity) {
                    return
                }

                this.throttle_save_user_progress()
            },
        },
        locale: {
            async handler(val) {
                this.$i18n.locale = val

                const rtl = ['ar', 'arc', 'dv', 'fa', 'ha', 'he', 'khw', 'ks', 'ku', 'ps', 'ur', 'yi']
                this.$vuetify.rtl = rtl.includes(val)

                if (['sv', 'en'].includes(val)) {
                    if (!this.locale_translation) return

                    return this.set_state_property({
                        property: 'locale_translation',
                        data: null,
                    })
                }

                const locale_translation = await import(`@/translations/${val}.json`)
                // TODO: should probably change structure of the translation files
                this.set_state_property({
                    property: 'locale_translation',
                    data: locale_translation.translations.reduce((prev, curr) => {
                        prev[curr.en_text] = curr.translation
                        return prev
                    }, {}),
                })
            },
            immediate: true,
        },
        // application: {
        //     handler(new_application, old_application) {
        //         if (!new_application || !old_application) {
        //             return
        //         }
        //         if (new_application._etag === old_application._etag) {
        //             return
        //         }
        //
        //         if (new_application?.storage_cleanup_date !== old_application?.storage_cleanup_date) {
        //             console.log('cleanup storage')
        //             caches.keys().then((cache_keys) => {
        //                 cache_keys.forEach((key) => {
        //                     caches.delete(key)
        //                 })
        //             })
        //             const locale_storage_keys_to_clear = ['vuex_persist_48h', 'vuex_persist_one_day']
        //             for (let key of locale_storage_keys_to_clear) {
        //                 localStorage.removeItem(key)
        //             }
        //             window.location.reload()
        //         } else {
        //             console.log('Application updated but storage_cleanup_date is same')
        //             console.log('new_application', new_application)
        //             console.log('old_application', old_application)
        //         }
        //     },
        //     immediate: true,
        // },
        programs(val) {
            if (!val || !val.length) return
            this.ensure_no_duplicates('programs', val)
        },
        modules(val) {
            if (!val || !val.length) return
            this.ensure_no_duplicates('modules', val)
        },
        courses(val) {
            if (!val || !val.length) return
            this.ensure_no_duplicates('courses', val)
        },
    },
    methods: {
        start_refresh_token_timer() {
            setInterval(() => {
                const time = this.decoded_token.exp
                if (this.refresh_token_in_progress || !time) return

                if ((time - 30) * 1000 <= Date.now()) {
                    this.refresh_token()
                }
            }, 10000)
        },
        init_statistics_tracking() {
            this.app_init_statistics_tracking()

            document.addEventListener('click', () => {
                this.statistics_clicks += 1
            })
            this.statistic_click_interval = setInterval(() => {
                this._increase_clicks_statistics(this.statistics_clicks)
                this.statistics_clicks = 0
            }, 1000 * 5)
            this.save_statistics_interval = setInterval(this.app_save_user_statistics_gathered_data, 1000 * 60 * 5)
        },
        ensure_no_duplicates(property, items) {
            let duplicates_found = false

            const loaded_ids = []
            const items_without_duplicates = []

            items.forEach((x) => {
                if (loaded_ids.find((id) => id === x._id)) {
                    duplicates_found = true
                } else {
                    items_without_duplicates.push(x)
                    loaded_ids.push(x._id)
                }
            })

            if (duplicates_found) {
                this.set_state_property({
                    property: property,
                    data: items_without_duplicates,
                })
            }
        },
        on_scroll() {
            // Solves https://github.com/vuetifyjs/vuetify/issues/9993
            const {the_header} = this.$refs
            const {app_bar} = the_header.$refs

            if (app_bar.currentScroll < app_bar.currentThreshold) {
                app_bar.isActive = true
            }
        },
        process_url_query_parameters() {
            const config = {
                token: {
                    method: this.handle_token,
                    clear_path: true,
                },
                error: {
                    method: this.handle_token_error,
                    clear_path: true,
                },
            }

            let clear_path = false
            const query = this.$route.query
            Object.entries(config).forEach(([k, v]) => {
                if (query[k]) {
                    clear_path = clear_path || v.clear_path
                    v.method(query[k])
                }
            })

            if (clear_path) {
                this.$router.replace({query: null})
            }
        },
        handle_token(token) {
            if (token) {
                this.set_state_property({
                    property: 'token',
                    data: token,
                })
            }
        },
        handle_token_error(token) {
            const data = VueJwtDecode.decode(token)
            this.push_state_property({
                property: 'messages',
                data: {
                    message: data['_locale_message'],
                    color: 'red',
                    timeout: 10 * 1000,
                },
            })
        },
        async load_total_number_of_programs() {
            const response = await this.api_get({
                url: 'x-u2work--programs',
                params: {
                    projection: {_id: 1},
                    max_results: 1,
                },
            })
            this.set_state_property({
                property: 'total_programs',
                data: response.data._meta.total,
            })
        },
        async load_total_number_of_modules() {
            const response = await this.api_get({
                url: 'x-u2work--modules',
                params: {
                    projection: {_id: 1},
                    max_results: 1,
                },
            })
            this.set_state_property({
                property: 'total_modules',
                data: response.data._meta.total,
            })
        },
        async load_total_number_of_courses() {
            const response = await this.api_get({
                url: 'x-u2work--courses',
                params: {
                    projection: {_id: 1},
                    max_results: 1,
                },
            })
            this.set_state_property({
                property: 'total_courses',
                data: response.data._meta.total,
            })
        },
        async load_user_extended_data() {
            await this.api_get({
                url: `user--extended-data-light`,
                commit: true,
                property: 'user_extended_data',
            })
        },
        async load_total_number_of_interview_questions() {
            const response = await this.api_get({
                url: 'x-u2work--interview-questions',
                params: {
                    max_results: 1,
                    where: {is_enabled: true},
                    projection: {_id: 1},
                },
            })
            this.set_state_property({
                property: 'total_interview_questions',
                data: response.data['_meta'].total,
            })
        },
        async load_and_validate_application() {
            const response = await this.api_get({
                url: 'applications',
                params: {
                    where: {
                        name: 'u2work',
                    },
                },
            })

            const [item] = response.data['_items']
            let clear_and_reload = false

            if (this.application) {
                const current_application_date = new Date(this.application['_updated'])
                const new_application_date = new Date(item['_updated'])
                if (current_application_date < new_application_date) clear_and_reload = true
            }

            this.set_state_property({
                property: 'application',
                data: item,
            })

            if (clear_and_reload) {
                const locale_storage_keys_to_clear = ['vuex_persist_48h', 'vuex_persist_one_day']
                for (let key of locale_storage_keys_to_clear) {
                    localStorage.removeItem(key)
                }
                window.location.reload()
            }
        },
        async load_organization_features() {
            const response = await this.api_get({
                url: 'x-u2work--organization-features',
                where: {
                    organization: this.user_extended_data.organization,
                },
            })
            if (response.status === 200 && response.data['_items'].length) {
                const [item] = response.data['_items']
                for (const key in item.features) {
                    const data = item.features[key] || false
                    this.update_key_in_state_property({property: 'features', data, key})
                }
            }
        },
        async load_nudging_settings() {
            const response = await this.api_get({
                url: 'x-u2work--nudging-settings',
                params: {
                    where: {
                        user: this.user_extended_data.user,
                    },
                },
            })
            if (response.status === 200) {
                const items = response.data['_items']
                if (items.length) {
                    const [item] = items
                    this.set_state_property({
                        property: 'nudging',
                        data: item,
                    })
                } else {
                    await this.create_nudging_settings()
                }
            }
        },
        async create_nudging_settings() {
            const response = await this.api_post({
                url: 'x-u2work--nudging-settings',
                data: {
                    user: this.user_extended_data.user,
                    nudging_enabled: true,
                },
            })
            if (response.status === 201) {
                const data = {...response.data, nudging_enabled: true}
                this.set_state_property({
                    property: 'nudging',
                    data: data,
                })
            }
        },
        async refresh_token() {
            try {
                const result = await this.api_post({
                    url: 'authenticates/refresh-token',
                })
                if (result.status === 202) {
                    return // Token recently refreshed
                }

                this.set_state_property({
                    property: 'token',
                    data: result?.data?.token,
                })
            } catch (e) {
                this.set_state_property({
                    property: 'token',
                    data: null,
                })
                console.log('failed to refresh token')
            } finally {
                this.set_state_property({
                    property: 'token_has_expired',
                    data: false,
                })
            }
        },
        ...mapActions(['set_state_property', 'push_state_property']),
    },
    beforeCreate() {
    },
    created() {
    },
    beforeMount() {
    },
    mounted() {
        window.onbeforeunload = () => {
            this.save_latest_activity_on_exit()
            this.app_save_user_statistics_gathered_data()
            clearInterval(this.statistic_click_interval)
            clearInterval(this.save_statistics_interval)
        }
        this.start_refresh_token_timer()
        this.process_url_query_parameters()
        if (this.$route.name !== 'Login' && !this.token) {
            this.$router.push({
                name: 'Login',
                query: {...this.$route.query},
            })
        } else if (this.token) {
            this.refresh_token()
        }
    },
    beforeUpdate() {
    },
    updated() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
}
</script>

<style lang="sass"></style>
